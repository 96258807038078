/* Container Styling */
.users-page {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .header {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Search Bar Styling */
  .search-form {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .search-input {
    padding: 8px;
    width: 250px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    outline: none;
    font-size: 14px;
  }
  
  .search-input:focus {
    border-color: #007bff;
  }
  
  .search-button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    font-size: 14px;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  
  /* Table Styling */
  .table-container {
    overflow-x: auto;
  }
  
  .users-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .users-table th,
  .users-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .users-table th {
    background-color: #007bff;
    color: white;
  }
  
  .users-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .users-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Loading Spinner */
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #ccc;
    border-top: 6px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Error Message */
  .error-message {
    color: red;
    text-align: center;
  }

  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  
  .pagination-button {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    font-size: 1rem;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-info {
    font-size: 1rem;
    margin: 0 1rem;
  }
  